<template>
    <div>
        <div class="header">
            <span class="header_txt">直播送礼</span>
        </div>
        <div class="header_boxs">
            <div class="header_box">
                <div class="left">
                    <img src="@/assets/live.png" alt="">
                    <span class="left_span">选择直播：</span>
                    <Select v-model="storePrams.live_id" style="width:200px;margin-right: 20px;" @on-change="liveChange">
                        <Option v-for="item in liveList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                    <Select v-model="storePrams.type" style="width:200px;margin-right: 10px;" placeholder="选择类别" @on-change="typeChange">
                        <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                    <button class="header_btn" @click="addClass">添加</button>
                </div>
                <div class="right"></div>
            </div>
            <div v-if="storePrams.type == 2" class="header-foot">
                <!-- <div class="header-btn" style="margin-right:15px" @click="medalDetails">详情</div> -->
                <div class="header-btn" @click="personalDetails">个人详情</div>
            </div>
            <div v-else style="margin-bottom:20px;"></div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{ row, index }" slot="type">
                    <div v-if="row.type == 1" style="display:flex;align-items:center;height: 56px;justify-content:center">
                        <img src="@/assets/live/money.png" alt="" style="width: 33px;">
                    </div>
                    <div v-if="row.type == 2" style="display:flex;align-items:center;height: 56px;justify-content:center">
                        <img src="@/assets/live/medal.png" alt="" style="width: 33px;">
                    </div>
                </template>
                <template slot-scope="{ row, index }" slot="num">
                    <span v-if="row.type == 1">每人<span style="color:#409eff;font-weight:bold;">{{row.num}}</span>元</span>
                    <span v-if="row.type == 2">每人<span style="color:#409eff;font-weight:bold;">{{row.num}}</span>个</span>
                </template>
                <template slot-scope="{ row, index }" slot="manage">
                    <span class="infoModal_text" @click="receive(row)">领取人查看</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 领取人查看弹框 -->
        <Modal v-model="receiveModal" title="领取用户" width="50%">
            <div class="detailsModal_content">
                <Table :columns="receiveTable" :data="receiveDataTable" border :no-data-text="noDataText">
                    <template slot-scope="{ row }" slot="portrait">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                            <img v-if="row.customer.portrait" :src="row.customer.portrait" alt="" style="width: 50px;">
                            <div v-else style="width: 56px;height: 50px;background-color: #f5f7fa;color:#c0c4cc;line-height:50px;text-align:center;">加载失败</div>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="nickname">
                        <div>{{row.customer.nickname}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="phone">
                        <div>{{row.customer.phone}}</div>
                    </template>
                </Table>
            </div>
            <div class="footer">
                <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
            </div>
            <div slot="footer"></div>
        </Modal>
        <!-- 添加弹框 -->
        <Modal v-model="addModal" title="奖品发放" width="50%">
            <div>
                <Form ref="addListForm" :model="addListForm" :rules="addListFormRules" :label-width="160">
                    <FormItem label="当前直播在线人数:">
                        <Input type="text" disabled v-model="people" style="width: 300px;">
                            <span slot="append">人</span>
                        </Input>
                    </FormItem>
                    <FormItem label="选择类别:" prop="class">
                        <Select v-model="selectTypeModal" style="width:300px;" placeholder="选择类别" @on-change="typeChangeModal">
                            <Option v-for="item in typeListModal" :value="item.value" :key="item.value">{{ item.label }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem v-if="medalNumModal == 1" label="发放数量:" prop="number">
                        <Input type="text" v-model="addListForm.number" style="width: 300px;">
                            <span slot="prepend">每人</span>
                            <span slot="append">元</span>
                        </Input>
                    </FormItem>
                    <FormItem v-else label="发放数量:">
                        <Input type="text" v-model="addListForm.number" style="width: 300px;" disabled>
                            <span slot="prepend">每人</span>
                            <span slot="append">个福卡</span>
                        </Input>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button type="primary" @click="addModalT('addListForm')">发放</Button>
                <Button @click="addModalF">取消</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {liveSelect, liveAddSendGift, liveGiftSendIndex, liveReceiveGiftList, live_online_num} from '@/api/index'
    export default {
        data() {
            return {
                liveList: [],
                //选择类别
                typeList: [
                    {
                        value: '0',
                        label: '所有'
                    },
                    {
                        value: '1',
                        label: '红包'
                    },
                    {
                        value: '2',
                        label: '福卡'
                    }
                ],
                typeListModal: [
                    {
                        value: '1',
                        label: '红包'
                    },
                    {
                        value: '2',
                        label: '福卡'
                    }
                ],
                selectTypeModal:'0',
                //table
                liveTable: [
                    {title: '类型',slot: 'type',align:'center'},
                    {title: '数量',slot: 'num',align:'center'},
                    {title: '发放时间',key: 'created_at',align:'center'},
                    {title: '操作',slot: 'manage',align:'center'},
                ],
                dataTable: [
                    // { type: '',num: '',created_at: ''}
                ],
                //page
                total: 10,
                storePrams: {
                    page: 1,
                    limit: 30,
                    live_id: 1,
                    type: '0'
                },
                totalModal: 10,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    live_id: 1,
                    id: 1
                },
                receiveModal:false,
                receiveTable: [
                    {title: '头像',slot: 'portrait',align:'center'},
                    {title: '昵称',slot: 'nickname',align:'center'},
                    {title: '手机号',slot: 'phone',align:'center'},
                ],
                receiveDataTable: [
                    // {portrait: '',nickname: '',phone: ''}
                ],
                noDataText:'暂无数据',
                addModal:false,
                addListForm:{
                    class:'',
                    number:'',
                },
                //添加弹框-表单验证
                addListFormRules: {
                    number: [
                        { required: true, message: '请输入发送数量', trigger: 'blur' }
                    ],
                },
                medalNumModal: 0,
                people: 0
            }
        },
        mounted(){
            this.liveSelect()
            this.liveGiftSendIndex()
            this.live_online_num()
        },
        methods: {
            live_online_num(){
                live_online_num({live_id : this.storePrams.live_id}).then(res => {
                    this.people = res.data
                })
            },
            // 送礼列表
            liveGiftSendIndex(){
                liveGiftSendIndex(this.storePrams).then(res => {
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            liveSelect(){
                liveSelect().then(res => {
                    this.liveList = res.data
                })
            },
            liveChange(e){
                console.log('直播间eee',e)
                this.storePrams.live_id = e
                this.liveGiftSendIndex()
                this.live_online_num()
            },
            // 选择类别-勋章
            typeChange(e){
                this.storePrams.type = e
                this.liveGiftSendIndex()
            },
            typeChangeModal(e){
                this.medalNumModal = e
                if(e == 2){
                    this.addListForm.number = 1
                }
            },
            // 勋章详情
            medalDetails(){
                this.$router.push({path: '/live/liveIn/liveingift/details'})
            },
            personalDetails(){
                this.$router.push({path: '/live/liveIn/liveingift/personalDetails'})
            },
            addClass(){
                this.addModal = true                
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.liveGiftSendIndex()
            },
            receive(e){
                this.storePramsModal.live_id = this.storePrams.live_id
                this.storePramsModal.id = e.id
                // 领取礼品记录接口
                liveReceiveGiftList(this.storePramsModal).then(res => {
                    this.totalModal = res.data.total
                    this.receiveDataTable = res.data.data
                })
                this.receiveModal = true
            },
            pageChangeModal(index) {
                this.storePramsModal.page = index;
            },
            // 添加送礼
            addModalT(name){
                if(this.medalNumModal == 1 || this.medalNumModal == 2){
                    let data = {
                        type: this.medalNumModal,
                        num: this.addListForm.number,
                        live_id: this.storePrams.live_id,
                    }
                    liveAddSendGift(data).then(res => {
                        this.$refs[name].validate((valid) => {
                            if (valid) {
                                this.$Message.success({
                                    background: true,
                                    content: '发送成功',
                                    closable: true
                                });
                                this.addModal = false
                            }
                        }) 
                        this.liveGiftSendIndex()
                    })
                }else{
                    console.log('添加送礼时类别不为所有(0)')
                }
            },
            addModalF(){
                this.addModal = false
            },
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_boxs{
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin: 10px 0;
}
.header_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-foot{
    display: flex;
    margin-top: 20px;
}
.header-btn{
    background-color: #409eff;
    color: #fff;
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}
.header_btn{
    width: 70px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.left{
    display: flex;
    align-items: center;
}
.footer{
    margin: 20px 0;
    text-align: center;
}
.left_span{
    height: 21px;
    color: #374a70;
    font-weight: 700;
    font-size: 16px;
    margin-right: 4px;
    margin-left: 2px;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
::v-deep .ivu-select-placeholder{
    height: 40px !important;
    line-height: 40px !important;
}
.infoModal_text{
    color: #409eff;
    margin: 10px;
    font-size: 12px;
    cursor: pointer;
}
::v-deep .ivu-input.ivu-input-default{
    height: 40px;
}
</style>